<script>
import Base from '@backend/Base.vue';

import BOGen from '@helper/BOGen';

export default {
	name: "MonthlyFee",
	extends: Base,
  data(){
    return {
		Name:"MonthlyFee",
		userLevel:0,
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
	this.refreshData()
	this.filter.level = ""
	},
  watch:{
    '$route.query'(){
		this.refreshData()
	},
 //    'filter.level'(v){
	// 	this.search()
	// },
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">
                <div class="card-body">
	                <VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
								<h5 class="card-title">{{ObjectName}} List</h5>
							</div>
							<div class="col-sm-3">
							</div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                </div>
                <div class="table-responsive ajax-table">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th><SortField name="bu_full_name"></SortField></th>
								<th>Payment (Rp)</th>
								<th>Attachment</th>
								<th>Pay Date</th>
								<th>Status</th>

								<th width="120px" v-if="userLevel.bu_level == 1">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,k) in data.data" :key="k">
								<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{v.bu_full_name}}</td>
								<td>{{v.amf_pay_amount}}</td>
								<td>
                                    <a :href="uploader(v.amf_file_attachment)" target="_blank" class="icon_action"  v-tooltip="'View'"><i class="ti-eye"></i></a>
                                </td>
								<td>{{v.amf_pay_at}}</td>
								<td><StatusLabel :type="'approve'" :status="v.amf_status"></StatusLabel></td>
								<td class="btn-action" v-if="userLevel.bu_level == 1">
									<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
									<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr v-if="NotFound">
								<td colspan="99">
									<h3 class="tc">{{NotFound}}</h3>
								</td>
							</tr>
							<tr v-if="data.data===false">
								<td colspan="99">
									<LoadingSpinner light></LoadingSpinner>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-body">
					<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
             </div>
          </div>
       </div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
                      <div class="col-md-8">
                         <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="bu_full_name" :attr="{readonly:'readonly'}" v-model="row.bu_full_name"></BoField>
                            <BoField name="amf_pay_at">
								<DatePicker name="amf_pay_at" v-bind="validation('amf_pay_at')" v-model="row.amf_pay_at" placeholder="Pay Date"></DatePicker>
							</BoField>
							
						</div>
						<div class="col-sm-5">
                            <BoFieldGroup name="amf_pay_amount" :attr="validation('amf_pay_amount')">
                                <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">RP</span>
                                </div>
                                <input type="number" class="form-control" name="amf_pay_amount" v-model="row.amf_pay_amount" >
                            </BoFieldGroup>
							<div class="row">	
								<div class="col-7">	
									<BoField name="amf_status" v-if="userLevel.bu_level == 1">
										<div class="row">
											<radio name="amf_status" v-model="row.amf_status" option="P" :attr="validation('amf_status')">Need Approval</radio>
											<radio name="amf_status" v-model="row.amf_status" option="Y" >Verify</radio>
											<radio name="amf_status" v-model="row.amf_status" option="N">Reject</radio>
										</div>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-sm-2">							
							<BoField name="amf_file_attachment">
								<Uploader name="amf_file_attachment" type="document" uploadType="upload" v-model="row.amf_file_attachment"></Uploader>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-rounded btn-info btn-outline btn-1e btn-loading">Save Payment</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
